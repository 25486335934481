<template>
  <el-dialog :title="$t('maintWorkOrder.featureDetails')" :close-on-click-modal="false" :visible.sync="dialogVisible"
    width="60%" top="2vh" @close="onDialogClose">
    <div :v-loading="contentLoading" class="body">
      <el-form ref="formValidate" class="form" label-width="110px" :model="MODSalesOrder" :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$t('maintWorkOrder.salesOrderNo')">
            <el-input v-model="MODSalesOrder.salesOrderNo" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('maintWorkOrder.contacts')">
            <el-input v-model="MODSalesOrder.contact" disabled></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('maintWorkOrder.phone')">
            <el-input v-model="MODSalesOrder.mobile" disabled></el-input>
          </el-form-item>
          <el-form-item>
          </el-form-item>
        </div>
        <div v-if="modSalesOrderPropertiesP1.length > 0" style="margin-bottom: 10px">
          控制柜参数
        </div>
        <el-table v-if="modSalesOrderPropertiesP1.length > 0" border stripe :data="modSalesOrderPropertiesP1">
          <el-table-column prop="propertyNo" :label="$t('maintWorkOrder.featureNumber')"
            align="center"></el-table-column>
          <el-table-column prop="propertyName" :label="$t('maintWorkOrder.featureName')"
            align="center"></el-table-column>
          <el-table-column prop="propertyValue" :label="$t('maintWorkOrder.featureValue')"
            align="center"></el-table-column>
        </el-table>
        <div v-if="modSalesOrderPropertiesP2.length > 0" style="margin-bottom: 10px;margin-top: 10px">
          大打包参数
        </div>
        <el-table v-if="modSalesOrderPropertiesP2.length > 0" border stripe :data="modSalesOrderPropertiesP2">
          <el-table-column prop="propertyNo" :label="$t('maintWorkOrder.featureNumber')"
            align="center"></el-table-column>
          <el-table-column prop="propertyName" :label="$t('maintWorkOrder.featureName')"
            align="center"></el-table-column>
          <el-table-column prop="propertyValue" :label="$t('maintWorkOrder.featureValue')"
            align="center"></el-table-column>
        </el-table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      contentLoading: false,
      filter: "",
      MODSalesOrder: {
        id: 0,
        salesOrderNo: "",
        mobile: "",
        contact: "",
        creatorName: "",
        modSalesOrderProperties: [],
      },
      modSalesOrderPropertiesP1: [],
      modSalesOrderPropertiesP2: [],
      ruleValidate: {
      },
    };
  },
  computed: {},
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.MODSalesOrder.id = id;
      this.getData();
    },
    getData() {
      this.$http.get("mODSalesOrders/" + this.MODSalesOrder.id).then(res => {
        console.log(res);
        this.MODSalesOrder = Object.assign(this.MODSalesOrder, res.data);
        if (this.MODSalesOrder.modSalesOrderProperties.length > 0) {
          this.MODSalesOrder.modSalesOrderProperties.forEach(item => {
            if (item.propertyNo.includes("P1")) {
              this.modSalesOrderPropertiesP1.push(item);
            } else {
              this.modSalesOrderPropertiesP2.push(item);
            }
          });
        }

        this.contentLoading = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    onDialogClose() {
      this.MODSalesOrder.id = 0;
      this.MODSalesOrder.salesOrderNo = "";
      this.MODSalesOrder.mobile = "";
      this.MODSalesOrder.contact = "";
      this.MODSalesOrder.creatorName = "";
      this.MODSalesOrder.modSalesOrderProperties = [];
      this.modSalesOrderPropertiesP1 = [];
      this.modSalesOrderPropertiesP2 = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
